<template>
  <div class="author">
    <div class="title relative">
      <h2 style="font-size: 24px">专栏人物</h2>
      <div class="all cursorP" @click="attentionInfo">
        换一换
        <i class="el-icon-refresh"></i>
      </div>
    </div>
    <ul class="peoples flex flex-wrap">
      <li v-for="(item,index) in authorList" :key="index" @click="goRouter('/section/sectionCenter',item.id)">
        <img :src="item.avatar" alt="" :title="item.realname" class="avatar err_avatar"/>
        <h3 class="line_clamp1">{{ item.realname }}</h3>
        <p class="line_clamp1">{{ item.breifdescrition }}</p>
        <div class="buttons" :class="item.userFocus?'default':''" @click.stop="lock(item.id,item)">
          <i class="el-icon-plus" v-if="!item.userFocus"></i>
          {{ item.userFocus ? '已关注' : '关注' }}
        </div>
      </li>
    </ul>
    <router-link class="a_all" :to="url" target="_blank" title="看专栏">看专栏</router-link>
    <login-prompt :goShow="isHide" @close="handleClose" title="登录" text="登录之后才可以关注"></login-prompt>
  </div>
</template>

<script>
import loginPrompt from "@/components/public/loginPrompt";
export default {
  name: "sectionAuthor",
  props:{
    url:''
  },
  components:{
    loginPrompt
  },
  data(){
    return{
      authorList:[],
      isHide:false,
      isLogin:'',
    }
  },
  methods:{
    //作者列表
    async attentionInfo() {
      try {
        let res = await this.$api.getAuthorsApi();
        if (res.data.success){
          this.authorList = res.data.data.records;
          this.isLogin = this.$store.state.userType || JSON.parse(localStorage.getItem('userType'));
        }
      }catch (e){}
    },
    //关注
    lock(id, info) {
      if (this.isLogin) {
        this.$api.focusAuthorApi({id: id}).then((res) => {
          if (res.data.success) {
            info.userFocus = !info.userFocus;
            if (info.userFocus) {
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
          }
        });
      } else {
        this.isHide = true;
      }
    },
    handleClose(){
      this.isHide = false;
    },
    goRouter(url,id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
  },
  created(){
    this.attentionInfo();
  }
}
</script>

<style scoped lang="less">
//专栏人物
.author {
  margin: 20px 0;
  .title {
    padding: 20px 0;

    .all {
      position: absolute;
      right: 0;
      top: 20px;
    }
    h2,.all {
      color: #00924c;
    }
  }

  .peoples {

    li {
      position: relative;
      width: 190px;
      height: 230px;
      margin-right: 9px;
      border: 1px solid #e1e1e1;
      text-align: center;
      cursor: pointer;
      .avatar {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        margin-top: 21px;
      }

      h3 {
        font-size: 16px;
        font-weight: 400;
        padding: 0 10px;
        margin: 5px 0;
      }

      p {
        color: #999999;
        padding: 0 10px;
      }

      .buttons {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        margin: 0 auto;
        width: 88px;
        height: 34px;
        line-height: 34px;
        -webkit-border-radius: 17px;
        -moz-border-radius: 17px;
        border-radius: 17px;
        color: white;
        background: #00924c;
        cursor: pointer;
      }
      .buttons:hover{opacity: .7}
      .default {
        border: 1px solid #cccccc;
        color: #666666;
        background: transparent;

        i {
          font-size: 12px;
        }
      }

      .default {
        border: 1px solid #cccccc;
        color: #666666;
        background: transparent;
      }
    }
    li:hover{
      h3{
        color: #00924b;
      }
    }
    li:last-child {
      margin-right: 0;
    }
  }

  .a_all {
    display: block;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: white;
    background: #00924c;
    margin: 20px 0;
    transition: all .8s;
  }
  .a_all:hover{opacity: .7}
  .a_all:active {
    transform: scale(.9);
  }
}
</style>