<template>
  <div class="problem">
    <div class="title relative">
      <h2>问答</h2>
    </div>
    <div class="asklib">
      <ul>
        <li class="relative" v-for="(item, index) in list" :key="index">
          <router-link
            :to="url + 'askDetail?id=' + item.id"
            :title="item.askName"
            target="_blank"
            class="rs"
          >
            <div class="top flex">
              <img
                :src="item.askPhoto"
                alt
                :title="item.askName"
                :class="item.askPhoto ? 'img' : 'err_avatar'"
                class="logo"
              />
              <div class="name">{{ item.askName }}</div>
            </div>
            <div class="text line_clamp2">{{ item.askTitle }}</div>
            <div class="bottom">
              <span>{{ item.answerNumber }}回答</span>
              <span>{{ item.viewNumber }}阅读</span>
              <span>{{ item.collectNumber }}关注</span>
              <!--<div class="buttons">我来回答</div>-->
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <router-link :to="url" target="_blank" class="more">
      查看全部
      <i class="el-icon-arrow-right"></i>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "asklib",
  props: {
    url: "",
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    //初始化List
    async info() {
      try {
        let res = await this.$api.askListApi({ pageNum: 1, pageSize: 5 });
        if (res.data.success) {
          this.list = res.data.data.records;
        }
      } catch (e) {}
    },
  },
  created() {
    this.info();
  },
};
</script>

<style scoped lang="less">
.problem {
  border: 1px solid #e1e1e1;
  .title {
    padding: 22px 0;
    margin: 0 20px;

    h2 {
      font-size: 22px;
    }
  }

  .asklib {
    margin: 0 20px;

    li {
      height: 130px;
      border-bottom: 1px dashed #e1e1e1;

      .rs {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .top {
        padding-top: 16px;
        cursor: pointer;
        margin-bottom: 10px;

        .logo {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }

        .name {
          color: #666666;

          span {
            padding-left: 10px;
          }
        }
      }

      .text {
        font-size: 14px;
        line-height: 20px;
        height: 40px;
        // display: flex;
        // align-items: center;
      }

      .bottom {
        width: 100%;
        height: 20px;
        font-size: 12px;
        color: #999999;
        margin-top: 5px;

        span {
          padding-right: 20px;
        }

        .buttons {
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 2px 5px;
          border: 1px solid #00b094;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          font-size: 12px;
          color: #00b094;
          cursor: pointer;
        }
      }
    }

    li:last-child {
      border: none;
    }
  }

  .more {
    display: block;
    width: 90%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin: 20px auto;
    color: #666666;
    background: #fafafa;

    i {
      font-size: 12px;
    }
  }
  .more:hover{
    color: #00924B;
  }
}
</style>
