
<template>
  <div class="tips" v-if="showTips">
    <span v-if="key == 0"
      >绑定微信，投递消息直接微信通知，不错过任何{{text}}！
    </span>
    <span v-else>担心看不到{{type == 2?'求职者':'用人单位'}}的回复？绑定微信，不错过任何消息！ </span>
    <button
      class="btn"
      @click="
        login();
        setKey();
      "
    >
      立即绑定
    </button>
    <el-dialog title="扫码绑定微信" :visible.sync="dialogVisible" width="290px">
      <img :src="src" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["pointKey","type"],
  data() {
    return {
      text:"面试机会",
      loginType:"",
      uuid: "",
      key: "0",
      src: "",
      code: "",
      dialogVisible: false,
      _timeInterval: "",
      showTips:false,
    };
  },
  methods: {
    // 查詢是否綁定過
    getShowTips(){
      this.$axios.get("/api/app-jycy-member/getListOtherLogin").then((res)=>{
        if(res.data.data.weixin == 0){
          this.showTips = true;
        }
      })
    },
    //  获取uuid和key
    getKey() {
      this.$axios.get("/api/app-jycy-member/getAbTextKey").then((res) => {
        this.uuid = res.data.data.uuid;
        this.key = res.data.data.key;
      });
    },
    // 向后台发送UUID，key，点击位置
    setKey() {
      this.$axios.post("/api/app-jycy-member/setAbTextKey", {
        uuid: this.uuid,
        key: this.key,
        pointKey: this.pointKey,
      });
    },
    // 绑定微信，赋值图片src属性
    login(argument) {
      this.$axios
        .get(
          "https://www.cdpee.org.cn/api/app-jycy-wechat/wx/loginStart?key=" +
            this.uuid
        )
        .then((res) => {
          this.src = res.data.data.url;
          this.dialogVisible = true;
          this.code = res.data.data.code;
        });
      this._timeInterval = setInterval(() => {
        this.checkLogin();
      }, 5000);
    },
    checkLogin() {
      this.$axios
        .get(
          "https://www.cdpee.org.cn/api/app-jycy-wechat/wx/loginStatusCheck?key=" +
            this.code
        )
        .then((res) => {
          if (res.data.success) {
            window.clearInterval(this._timeInterval);
            this.dialogVisible = false;
            this.showTips = false;
            this.$message({
              message:'綁定成功',
              type:'success'
            });
            // if (res.data.data) {
            //   if (res.data.data.openId) {
            //     if (res.data.data.user) {
            //       console.log("未绑定，进入绑定流程");
            //     } else {
            //       cosole.log(res.data.data.userId);
            //     }
            //   }
            // }
          }
        });
    },
  },
  created() {
    this.getKey();
    this.getShowTips();
    this.userType = JSON.parse(localStorage.getItem("userType")).userType
    if(this.userType == 2){
      this.text = "优秀人才"
    }
  },
};
</script>
<style lang="less" scoped>
.tips {
  margin: 0 auto;
  width: 1200px;
  height: 40px;
  background-color: rgba(255, 223, 37, 1);
  color: #333;
  border-radius: 6px;
  padding-left: 15px;
  span {
    margin-top: 10px;
    display: inline-block;
  }
  .btn {
    margin-left: 40px;
    border-radius: 6px;
    width: 80px;
    height: 30px;
    margin-top: -5px;
    cursor: pointer;
    &:hover {
      color: #00924b;
    }
  }
  img {
    width: 200px;
  }
  /deep/.el-dialog__body {
    text-align: center;
  }
}
</style>