<template>
  <div>
    <el-dialog :visible.sync="show" title="委托人信息" custom-class="g-dialog-style g-baseinfo-dialog background" width="760px" :before-close="close">
      <div class="">
        <el-scrollbar class="g-el-scrollbar swiper" >
          <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">

             <el-form-item prop="principalIdcardFront" required="">
              <span slot="label">经&nbsp;办&nbsp;人&nbsp;<br>身份证人像面</span>
              <uploadimg :limit="1" list-type='text' field="publicizeWord" v-model="form.principalIdcardFront" :file-size="2" :file-type="['png', 'jpg', 'jpg']">
                <template slot="tips">
                  <div class="font-14 ml8">
                    <div class="color-787E7B mb8">须上传jpg/jpeg格式，且不超过2MB的图片</div>
                  </div>
                </template>
              </uploadimg>
            </el-form-item>
               <el-form-item prop="principalIdcardCon" required="">
              <span slot="label">经&nbsp;办&nbsp;人&nbsp;<br>身份证国徽面</span>
              <uploadimg :limit="1" list-type='text' field="publicizeWord" v-model="form.principalIdcardCon" :file-size="2" :file-type="['png', 'jpg', 'jpg']">
                <template slot="tips">
                  <div class="font-14 ml8">
                    <div class="color-787E7B mb8">须上传jpg/jpeg格式，且不超过2MB的图片</div>
                  </div>
                </template>
              </uploadimg>
            </el-form-item>
            <el-form-item prop="principalSealFile" required="">
              <span slot="label">授权委托书<br>盖公章证明文件</span>
              <uploadimg :limit="1" list-type='text' field="publicizeWord" v-model="form.principalSealFile" :file-size="2" :file-type="['png', 'jpg', 'jpg']">
                <template slot="tips">
                  <div class="font-14 ml8">
                    <div class="color-787E7B mb8">须上传jpg/jpeg格式，且不超过2MB的图片</div>
                    <div><a style="color:#28A46D;text-decoration: underline;" href="https://imgcdpee.cdpee.org.cn/wts.doc">下载模板</a></div>
                  </div>
                </template>
              </uploadimg>
            </el-form-item>

          </el-form>
        </el-scrollbar>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button plain @click="close">取消</el-button>
        <el-button type="primary" @click="auditStatuSubmit('form')">提交</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import uploadimg from './upload.vue';
import axios from 'axios';

export default {
  components: {
    uploadimg,
  },
  data() {
    return {
      show: false,
      isCropper: false,
      form: {
        id:'',
        principalIdcardCon:'',
        principalIdcardFront:'',
        principalSealFile:''
      },
      address: '',
      rules: {
        companyName: [{ required: true, message: '请输入企业名称', trigger: ['blur', 'submit'] }],
        logo: [{ required: true, message: '请上传企业LOGO', trigger: ['submit'] }],
        socialCredit: [
          { required: true, message: '请输入统一社会信用代码', trigger: ['submit', 'blur'] }
        ],
        pr: [{ required: true, message: '请选择企业类型', trigger: ['submit', 'blur'] }],
        mun: [{ required: true, message: '请选择企业规模', trigger: ['submit', 'blur'] }],
        // hyTop: [{ required: true, message: '请选行业领域', trigger: ['submit','blur'] }],
        registerProvinced: [
          { required: true, message: '请选择注册地点', trigger: ['submit', 'blur'] }
        ],
        content: [{ required: true, message: '请输入企业简介', trigger: ['submit', 'blur'] }],
        licenseUrl: [{ required: true, message: '请上传营业执照', trigger: ['submit', 'blur'] }],
        principalIdcardFront: [{ required: true, message: '请上传经办人身份证人像面', trigger: ['submit', 'blur'] }],
        principalIdcardCon: [{ required: true, message: '请上传经办人身份证国徽面', trigger: ['submit', 'blur'] }],
        principalSealFile: [{ required: true, message: '请上传授权委托书盖公章证明文件', trigger: ['submit', 'blur'] }]
      }
    };
  },

  methods: {


    getUpload(file, type) {
      let src = type ? file : file.file;
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      };
      console.log('src', src);
      let files = new FormData();
      files.append('file', src);
      axios.post('/api/app-jycy-disabled/uploadImg', files, config).then(res => {
        this.form.logo = res.data.msg;
        this.isCropper = type ? false : true;
      });
    },

    // 提交表单
    auditStatuSubmit(form) {
      this.$refs[form].validate(async  valid => {
        if (valid) {

            let that = this;
            // that.form.isHr = that.form.hrUrl && that.form.ldUrl ? '1' : '0';
            await this.$api.updateCompany({
              ...this.form
            });
            let res = await this.$axios.post('/api/app-jycy-company/com/updateCompanyPrincipal', this.form);
            if (res.data.success) {
              this.$message.success('提交成功');
              this.close(0);
              this.$emit('success');
              // this.$parent.getComInfo();
            } else {
              res.data.msg && this.$message.error(res.data.msg || '');
            }

        } else {
          this.$message.error('请完善表单内容后提交');
        }
      });

    },

    openDialog(el, data) {
      this.$refs[el].open(data);
    },

    open(data) {
      this.show = true;
      if(data.id) this.form.companyId = data.id;
    },
    close() {
      this.show = false;
    }
  },
  mounted() {}
};
</script>
<style lang="less">
.g-baseinfo-dialog {
  margin-top: 64px !important;
  .component-upload-image {
    & > div:nth-of-type(1) {
      float: left;
    }
  }
  .tips{
    margin-left:16px;
  }
  .el-form-item__label {
    font-size: 14px;
    color: #4b534f;
    line-height: 22px;
    margin-top: 9px;
  }
}
</style>
