<template>
  <div class="g-uploadimg-style">
    <el-upload :action="uploadImgUrl" :list-type="listType" :on-success="handleUploadSuccess" :before-upload="handleBeforeUpload" :limit="limit" :on-error="handleUploadError"
      :on-exceed="handleExceed" name="file" :show-file-list="false" :on-remove="handleRemove" :headers="uploadHeaders" :file-list="fileList" :on-preview="handlePictureCardPreview"
      :class="[this.fileList.length >= this.limit ? 'hide' :'', listType , limit > 1 ? 'multi' : 'single' ]">
      <div class="file-card-con" :id="id">
        <template v-if="limit == 1">
          <div class="img-card" v-if="fileList.length">
            <img :src="fileList[fileList.length-1].url" class="img_avatar block_inline" />
            <div class="edit-btn" @click.stop="">
              <i class="el-icon-zoom-in" @click="onZoom(fileList[fileList.length-1].url,1)"></i>
              <i class="el-icon-download mx8" @click="onDown(fileList[fileList.length-1].url)"></i>
              <i class="el-icon-delete" @click.stop="onDel"></i>
            </div>
          </div>
          <div class="upload-card" v-else>
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </div>
        </template>
        <div class="multi-card" v-else>
          <div class="img-card" v-for="(item,index) in fileList">
            <img :src="item.url" class="img_avatar block_inline" />
            <div class="edit-btn" @click.stop="">
              <i class="el-icon-zoom-in" @click="onZoom(index)"></i>
              <i class="el-icon-download mx8" @click="onDown(item.url)"></i>
              <i class="el-icon-delete" @click.stop="onDel2(index)"></i>
            </div>
          </div>
          <div class="upload-card">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </div>
        </div>
      </div>
    </el-upload>
    <div class="tips">
      <slot name="tips">
        <div class=" ml4 color-787E7B">{{fileTips.tips}}</div>
      </slot>
    </div>

  </div>
</template>
<script>
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import {  downloadFile } from '@/plugins/utils/utils.js';
var fileList = [];
export default {
  model: {
    event: 'change',
    prop: 'modelVlaue'
  },
  props: {
    modelVlaue: String,
    className: String,
    value: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 1
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 50
    },
    // 文件类型, 例如['png', 'jpg', 'jpg']
    fileType: {
      type: Array,
      default: () => ['png', 'jpg', 'jpg']
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },
    // 上传提示
    fileTips: {
      type: Object,
      default: () => ({})
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    // 字段
    field: {
      type: String,
      default: ''
    },
    initFile: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      // uploadImgUrl: process.env.VUE_APP_BASE_API + "/common/upload", // 上传的图片服务器地址
      uploadImgUrl: '/api/app-jycy-disabled/uploadImg', // 上传的图片服务器地址
      headers: {
        // Authorization: "Bearer " + getToken(),
      },
      fileList: [],
      viewer: null,
      id: 'filecard' + Date.now()
    };
  },
  watch: {
    modelVlaue: {
      handler(val) {
        this.setVal(val);
      },
      deep: true,
      immediate: true
    },
    initFile: {
      handler(val, old) {
        fileList= [];
        if (!val || !val.length) return;
        val = val || [];
        old = old || [];
        fileList = val;
        this.fileList = val;
        this.initView();
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    }
  },
  mounted() {
    this.setVal(this.modelVlaue);
    if (this.initFile.length > 0) {
      this.fileList = this.initFile || []; // 初始化文件列表
    }
    setTimeout(() => {
      if (fileList.length > 0 && this.fileList.length == 0) {
        console.log('当监听失效的时候执行');
        this.fileList = fileList;
      }
    }, 1000);
  },
  methods: {
    setVal(val) {
      if (val) {
        // 首先将值转为数组
        const list = Array.isArray(val) ? val : val.split(',');
        // 然后将数组转为对象数组
        this.fileList = list.map(item => {
          if (typeof item === 'string') {
            item = { name: item, url: item };
          }
          return item;
        });

        this.initView();
      } else {
        this.fileList = [];
        return [];
      }
    },
    onDown(url) {
      downloadFile(url);
      this.$message.success('下载成功，请在浏览器下载内容中查找');
    },
    initView() {
      setTimeout(() => {
        if (!this.viewer) {
          let that = this;
          this.viewer = new Viewer(document.querySelector('#' + this.id), {
            toolbar: false,
            tooltip: false,
            title: false,
            show: function () {
              // 动态加载图片后，更新实例
              that.viewer.update();
            }
          });
        }
      }, 1000);
    },
    onZoom(index, type) {
      if (type == 1) {
        this.viewer.url = function () {
          return index;
        };
        this.viewer.show();
      } else {
        this.viewer.view(index);
      }
    },
    onDel2(index) {
      this.fileList.splice(index, 1);
      this.$emit('input', this.fileList, this.field);
    },
    onDel() {
      this.fileList = [];
      this.$emit('change', ''); //针对绑定单个的
    },
    // 删除图片
    handleRemove(file, fileList) {
      const findex = this.fileList.map(f => f.name).indexOf(file.name);
      if (findex > -1) {
        this.fileList.splice(findex, 1);
        this.$emit('input', this.fileList, this.field);
      }
    },
    // 上传成功回调
    handleUploadSuccess(res) {
      var file = res.msg.split('/');
      this.fileList.push({ name: file[file.length - 1], url: res.msg });
      this.$emit('input', this.fileList, this.field); // 针对多个的
      this.$emit('change', this.fileList[0].url); //针对绑定单个的
      this.loading.close();
    },
    // 上传前loading加载
    handleBeforeUpload(file) {
      // console.log(file, 'file');
      let isImg = false;
      if (this.fileType.length) {
        let fileExtension = '';
        if (file.name.lastIndexOf('.') > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
        }
        isImg = this.fileType.some(type => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf('image') > -1;
      }

      if (!isImg) {
        this.$message.error(`文件格式不正确, 请上传${this.fileType.join('/')}格式文件!`);
        return false;
      }
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      this.loading = this.$loading({
        lock: true,
        text: '上传中',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    // 上传失败
    handleUploadError() {
      this.$message({
        type: 'error',
        message: '上传失败'
      });
      this.loading.close();
    },
    // 预览
    handlePictureCardPreview(file) {
      let fileExtension = '';
      if (this.listType == 'text') {
        window.open(file.url);
        return false;
      }
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = '';
      separator = separator || ',';
      for (let i in list) {
        strs += list[i].url.replace(this.baseUrl, '') + separator;
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : '';
    }
  }
};
</script>
<style lang="less" >
.g-uploadimg-style {
  display: flex;

  // .listType {
  //   width: 88px;
  //   flex-shrink: 0;
  // }
  .single {
    flex-shrink: 0;
    .el-upload--picture-card {
      width: 100%;
      height: 80px;
      border: none;
    }
    .file-card-con {
      .upload-card {
        display: flex;
        width: 88px;
        height: 88px;
        background: #f8f8f8;
        border-radius: 4px;
        border: 1px solid rgba(31, 41, 36, 0.1);
        justify-content: center;
        align-items: center;
      }
      .img-card {
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        width: 88px;
        height: 88px;
        img {
          width: 100%;
          height: 100%;
          background: #f8f8f8;
          object-fit: cover;
        }
        &:hover {
          .edit-btn {
            opacity: 1;
          }
        }
        .edit-btn {
          opacity: 0;
          display: flex;
          position: absolute;
          align-items: center;
          width: 88px;
          height: 88px;
          background-color: rgba(0, 0, 0, 0.5);
          left: 0;
          top: 0;
          color: #fff;
          justify-content: center;
          i {
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }
  }

  .multi {
    .el-upload--picture-card {
      width: 100%;
      border: none;
      background: none;
    }
    .multi-card {
      display: flex;
      flex-wrap: wrap;
      .img-card {
        margin-right: 16px;
        margin-bottom: 16px;
      }
    }
    .file-card-con {
      .upload-card {
        display: flex;
        width: 144px;
        height: 80px;
        background: #f8f8f8;
        border-radius: 4px;
        border: 1px solid rgba(31, 41, 36, 0.1);
        justify-content: center;
        align-items: center;
      }
      .img-card {
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        width: 144px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: #f8f8f8;
        }
        &:hover {
          .edit-btn {
            opacity: 1;
          }
        }
        .edit-btn {
          transition: opacity 0.3s;
          opacity: 0;
          display: flex;
          position: absolute;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          left: 0;
          top: 0;
          color: #fff;
          justify-content: center;
          i {
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>