<template>
  <div>
<!--    <tips :pointKey="0" type="2"></tips>-->
    <div class="enterprise-home">
      <div class="left">
        <div class="head">
          <ul>
            <li>
              <h5>-职位-</h5>
              <div class="position cursorR">
                <router-link to="/enterprise/publish" target="_blank">
                  <div class="pos">
                    <img src="../static/house/send.png" alt />
                    <p>发布职位</p>
                  </div>
                </router-link>

                <div class="pos cursorP" @click="refresh">
                  <img src="../static/house/refresh.png" alt />
                  <p>刷新职位</p>
                </div>
                <router-link to="/enterprise/post" title="职位管理">
                  <div class="pos">
                    <img src="../static/house/management.png" alt />
                    <p>职位管理</p>
                  </div>
                </router-link>
              </div>
            </li>
            <li>
              <h5>-人才-</h5>
              <div class="position cursorP">
                <router-link
                  title="最新人才"
                  :to="{
                    path: '/enterprise/recruiting',
                    query: { ids: 'first' },
                  }"
                  target="_blank"
                >
                  <div class="pos">
                    <img src="../static/house/talent.png" alt />
                    <p>最新人才</p>
                  </div>
                </router-link>
                <router-link
                  title="谁看过我"
                  :to="{
                    path: '/enterprise/recruiting',
                    query: { ids: 'third' },
                  }"
                  target="_blank"
                >
                  <div class="pos">
                    <img src="../static/house/look.png" alt />
                    <p>谁看过我</p>
                  </div>
                </router-link>
              </div>
            </li>
            <li>
              <h5>-简历-</h5>
              <div class="position cursorP">
                <router-link
                  :to="{ path: 'enterprise/resumes', query: { active: '0' } }"
                  title="查看简历"
                  target="_blank"
                >
                  <div class="pos">
                    <img src="../static/house/resume.png" alt />
                    <p>我收到的</p>
                  </div>
                </router-link>
                <router-link
                  :to="{ path: 'enterprise/resumes', query: { active: '1' } }"
                  title="收藏的"
                  target="_blank"
                >
                  <div class="pos">
                    <img src="../static/house/collect.png" alt />
                    <p>我收藏的</p>
                  </div>
                </router-link>
              </div>
            </li>
            <li class="bg1 cursorP">
              <router-link to="/enterprise/resumes?ids=1" target="_blank">
                <div>
                  <h6>待处理简历</h6>
                  <div class="line-two">
                    <p class="lefts">
                      {{ numList.noApplys || 0 }}
                      <span>份</span>
                    </p>
                    <p class="look">
                      去处理
                      <span class="el-icon-arrow-right"></span>
                    </p>
                  </div>
                </div>
              </router-link>
            </li>
            <li class="bg1 cursorP">
              <router-link
                title="面试邀请"
                to="/enterprise/resumes?ids=36"
                target="_blank"
              >
                <div class="cursorP">
                  <h6>面试邀请</h6>
                  <div class="line-two">
                    <p class="centers">
                      {{ numList.interviews || 0 }}
                      <span>个</span>
                    </p>
                    <p class="look">
                      查看
                      <span class="el-icon-arrow-right"></span>
                    </p>
                  </div>
                </div>
              </router-link>
            </li>
            <li class="bg1 cursorP">
              <router-link
                title="有意向"
                to="/enterprise/resumes?ids=3"
                target="_blank"
              >
                <div>
                  <h6>有意向</h6>
                  <div class="line-two">
                    <p class="rights">
                      {{ numList.intention || 0 }}
                      <span>个</span>
                    </p>
                    <p class="look">
                      查看
                      <span class="el-icon-arrow-right"></span>
                    </p>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <ul class="adList">
          <li v-for="(item, index) in adList" :key="index">
            <a :href="item.picUrl" :title="item.adName" target="_blank"
              ><img :src="item.picSrc" :alt="item.adName" :title="item.adName"
            /></a>
          </li>
        </ul>
        <div class="center">
          <div class="tops">
            <p>
              推荐
              <span>人才</span>
            </p>
            <router-link
              class="two cursorP"
              to="/enterprise/recruiting"
              target="_blank"
            >
              查看全部
              <span class="el-icon-arrow-right"></span>
            </router-link>
          </div>

          <div class="forin" v-for="item in getResumesList" :key="item.index">
            <Info :item="item"></Info>
          </div>
        </div>
        <div class="section" v-if="province == ''">
          <sectionAuthor url="/section/"></sectionAuthor>
        </div>
      </div>
      <div class="right">
        <div class="logos">
          <div class="imgs">
            <img :src="logo" alt class="err_logo" />
          </div>
          <p>{{ this.$store.state.login.userInfo.userName }}</p>
        </div>
        <div class="btn">
          <el-button
            type="primary"
            @click="goRouter('/enterprise/centre/index')"
            >企业中心
          </el-button>
          <el-button
            type="primary"
            @click="goRouter('/enterprise/centre/myneeds')"
            >我的需求
          </el-button>
        </div>
        <!-- 问答 -->
        <div class="ask cursorP" v-if="province == ''">
          <asklib url="/asklib/"></asklib>
        </div>
      </div>
      <div class="shows">
        <el-dialog :visible.sync="goShows" width="300px" :before-close="close">
          <div class="dislogs flexd" v-if="active == 0">
            <img src="../static/public/finish.png" alt="" />
            <h2>你的企业信息还未完善</h2>
            <p>为了不影响您的招聘，赶快去完善吧</p>
            <el-button
              type="primary"
              @click="goRouter('/enterprise/centre/infomanage/')"
              >立即完善
            </el-button>
          </div>
          <div class="dislogs flexd" v-if="active == 1">
            <img src="../static/public/xiugai.png" alt="" />
            <h2>您的企业信息审核未通过</h2>
            <p>请您修改后再次提交审核</p>
            <el-button
              type="primary"
              @click="goRouter('/enterprise/centre/infomanage/')"
              >立即修改
            </el-button>
          </div>
        </el-dialog>
      </div>
      <div class="shows">
        <el-dialog
          :visible.sync="goshowPass"
          width="300px"
          :before-close="close"
        >
          <div class="dislogs flexd">
            <div class="flex regist">
              <img src="../static/public/passCheck.png" alt="" />
            </div>
            <h2>您的密码设置的过于简单</h2>
            <p>为了您账户安全请重新设置密码！</p>
            <el-button
              type="primary"
              class="flexs"
              @click="goRouter('/settingPass/')"
            >
              去修改密码
            </el-button>
          </div>
        </el-dialog>
      </div>
      <a
        href="http://canjirenjiuye.mikecrm.com/OCYQyMa"
        target="_blank"
        class="questionnaire"
        title="企业问卷调查"
      >
        <img src="~/static/public/diaocha.png" alt="" />
      </a>
    </div>
    <mandatorDialog ref="mandatorDialog" @success="getDictComInfo"/>
  </div>
</template>

<script>
import Info from "components/enterprise/Info";
import asklib from "@/components/community/asklib";
import sectionAuthor from "@/components/community/sectionAuthor";
import Tips from "../components/public/tips.vue";
import mandatorDialog from "@/views/enterprise/home/components/mandator";
export default {
  components: {
    Info,
    asklib,
    sectionAuthor,
    Tips,
    mandatorDialog
  },
  data() {
    return {
      isfollow: false,
      getResumesList: [],
      authorList: [],
      comIndoLlist:[],
      logo: "",
      resumes: {
        pageNum: 1,
        pageSize: 6,
      },
      numList: [],
      goShows: false,
      active: 0,
      goshowPass: false,
      passCheck: true,
      adList: [],
      province: "",
    };
  },
  methods: {
    follow() {
      this.isfollow = !this.isfollow;
    },
    async attentionInfo() {
      try {
        let res = await this.$api.getAuthorsApi();
        if (res.data.success) {
          this.authorList = res.data.data.records;
        }
      } catch (e) {}
    },
    async selectApplyInfo() {
      this.$api.getReceiveCount().then((res) => {
        if (res.data.success) {
          this.numList = res.data.data;
          console.log(this.numList, 5555);
        }
      });
      this.perfect();
      this.province = localStorage.getItem("locationSite");
      console.log(this.province);
    },
    async refresh() {
      let that = this;
      let res = await this.$axios.post("/api/app-jycy-job/refreshAllJob");
      if (res.data.success) {
        that.$message.success("刷新成功");
      } else {
        that.$message.warning(res.data.msg);
      }
    },
    async perfect() {
      let that = this;
      let res = await this.$axios.get(
        "/api/app-jycy-company/com/comCenterBasicInfo"
      );
      if (res.data.success) {
        localStorage.setItem("basicInfo", JSON.stringify(res.data.data));
        if (res.data.data.auditStatus == 0) {
          this.goShows = true;
        } else if (res.data.data.auditStatus == 3) {
          this.active = 1;
          this.goShows = true;
        }
      } else {
        that.$message.warning(res.data.msg);
      }
    },
    goRouter(url, ids) {
      const { href } = this.$router.resolve({
        path: url,
        id: ids,
      });
      window.open(href);
      this.goShows = true;
    },
    close() {
      this.goShows = false;
      this.goshowPass = false;
    },
    // 推荐人才数据请求
    async getResumes() {
      let res = await this.$axios.post(
        "/api/app-jycy-disabled/getResumeByApply",
        this.resumes
      );
      if (res.data.success == false) {
        this.$message.error("请求异常，请联系后台！");
        return;
      }
      let data = this.$decrypt(res.data);
      this.getResumesList = data.data.records;
      this.ad();
    },
    async getDictComInfo() {
      let that = this;
      let res = await that.$api.getCompanyInfos();
      if (res.data.code === 200) {
        that.comIndoLlist = res.data.data;
      }
      if (!res.data.data.principalIdcardCon) {
        this.$confirm('您好，根据人社部最新规定，需要您尽快补全经办人身份证、授权委托书盖单位公章证明文件，感谢配合。', '系统提示', {
          confirmButtonText: '上传',
          cancelButtonText: '取消',
          // showCancelButton: false,
          // showClose: false,
          dangerouslyUseHTMLString: true,
          customClass: 'g-confirm-message-box warning',
          closeOnClickModal: false,
          type: 'warning'
        }).then(res => {
          this.$refs['mandatorDialog'].open(that.comIndoLlist)
          // this.$router.push('/companyLogin/authenticationrz');
        });
        return;
      }
    },
    async ad() {
      let res = await this.$api.adApi({
        classId: "ff8080817a0e63b1017a0f040bed016e",
      });
      if (res.data.code === 200) {
        this.adList = res.data.data;
      }
    },
  },
  created() {
    this.getResumes();
    this.selectApplyInfo();
    this.getDictComInfo();
    if (localStorage.getItem("userType")) {
      let user = JSON.parse(localStorage.getItem("userType"));
      this.logo = user.logo;
    }
    if (this.loginType) {
      if (localStorage.getItem("passCheck")) {
        this.passCheck = localStorage.getItem("passCheck");
        // this.goshowPass = true;
      }
    }
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/enterprise.less";

.shows {
  /deep/ .el-dialog {
    border-radius: 16px;
  }
}
</style>
